import classnames from 'classnames';
import React from 'react';

import styles from './HorizontalCardWithTitle.module.scss';
import { Props } from './HorizontalCardWithTitle.types';

const HorizontalCardWithTitle = ({ title, subtitles, className, onClick, rightActions }: Props) => (
  <div className={classnames(styles.container, className)} onClick={onClick}>
    <div style={{ flex: 1 }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>
        {subtitles?.map(subtitle => (
          <div>{subtitle}</div>
        ))}
      </div>
    </div>
    {rightActions}
  </div>
);

export default HorizontalCardWithTitle;

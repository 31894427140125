import React from 'react';
export function useCombinedRefs<T>(
  ...refs: (((instance: T | null) => void) | React.MutableRefObject<T | null> | null)[]
) {
  const targetRef = React.useRef<T | null>(null);

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

import Slider from '@bit/mui-org.material-ui.slider';
// peer dependency of material-ui.slider
// eslint-disable-next-line import/no-extraneous-dependencies
import { withStyles } from '@bit/mui-org.material-ui.styles';
import React from 'react';

import { Props } from './Slider.types';

const MarkLabel = ({ value }: { value?: React.ReactNode }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <p style={{ paddingLeft: 2 }}>|</p>
    <p>{value}</p>
  </div>
);

// Add the styles
const CustomSlider = withStyles({
  root: {
    color: '#2175d9',
    padding: '13px 0',
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#8a8a8a',
  },
  mark: {
    opacity: 0,
    backgroundColor: '#8a8a8a',
  },
  rail: {
    height: 2,
    backgroundColor: '#8a8a8a',
  },
  thumb: {
    height: 16,
    width: 16,
  },
  valueLabel: {
    whiteSpace: 'pre-wrap',
  },
})(Slider);

// Add a tiny | mark on top of the labels according to the design
export const NXNSlider = ({ marks, ...otherProps }: Props) => (
  <CustomSlider
    marks={marks.map(_ => ({ ..._, label: <MarkLabel value={_.label} /> }))}
    {...otherProps}
  />
);

export default NXNSlider;

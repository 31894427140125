import { LightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import PopupMenu from '../../Popups/PopupMenu';

import styles from './DropDown.module.scss';
import { Props } from './DropDown.types';

const DropDown = <T,>({
  placeholder,
  canBeReset,
  items,
  keyValueExtractor,
  onSelectItem,
  selectedItem,
  disabled,
  className,
}: Props<T>) => {
  const [opened, setOpened] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [resultWidth, setResultWidth] = useState(containerRef.current?.offsetWidth ?? 0);

  useEffect(() => {
    function handleResize() {
      setResultWidth(containerRef.current?.offsetWidth ?? 0);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef]);

  useEffect(() => {
    setResultWidth(containerRef.current?.offsetWidth ?? 0);
  }, [containerRef]);

  const spin = useSpring({
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  });

  return (
    <PopupMenu
      selectedId={selectedItem ? keyValueExtractor(selectedItem).key : 'canBeResetItem'}
      onOpen={() => {
        setOpened(true);
      }}
      onClose={() => {
        setOpened(false);
      }}
      disabled={disabled}
      trigger={
        <div
          ref={containerRef}
          className={classnames(
            styles.container,
            {
              [styles.containerFocused]: opened,
              [styles.containerWithSelectedValue]: selectedItem !== undefined,
              [styles.disabled]: disabled,
            },
            className
          )}
        >
          <span className={styles.placeholder}>
            {selectedItem ? keyValueExtractor(selectedItem).value : placeholder}
          </span>
          <animated.div
            className={classnames(styles.arrow, { [styles.disabledArrow]: disabled })}
            style={spin}
          >
            <LightArrow className={classnames({ [styles.disabledArrow]: disabled })} />
          </animated.div>
        </div>
      }
      arrow={false}
      width={resultWidth}
      animated
    >
      {canBeReset && (
        <PopupMenu.Item
          text={placeholder}
          onClick={() => onSelectItem(undefined)}
          id="canBeResetItem"
        />
      )}
      {items.map(item => (
        <PopupMenu.Item
          id={keyValueExtractor(item).key}
          text={keyValueExtractor(item).value}
          onClick={() => onSelectItem(item)}
        />
      ))}
    </PopupMenu>
  );
};

export default DropDown;

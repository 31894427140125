import {
  IllusEscalier,
  Loupe,
  Pasteque,
  RightArrow,
  Check,
  ClosePop,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState, useCallback, useRef, Ref, ReactElement, useEffect } from 'react';
import { FixedSizeList } from 'react-window';
import { Popup } from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import { useCombinedRefs } from '../../../Hooks/UtilityHooks';
import Loader from '../../../Loader';

import styles from './RawSearchInput.module.scss';
import {
  defaultProps,
  FETCH_STATUS,
  InputProps,
  Props,
  ResultsProps,
} from './RawSearchInput.types';

function highlightMatchingText(text: string, searchValue: string) {
  const reg = new RegExp(`(${searchValue})`, 'gi');
  const textParts = text.split(reg);
  return (
    <span>
      {textParts.map(part =>
        part.match(reg) ? <span className={styles.matchingText}>{part}</span> : part
      )}
    </span>
  );
}

function defaultPlaceholder(minLengthToSearch: number) {
  return minLengthToSearch > 0
    ? `indiquer ${minLengthToSearch} caractère${minLengthToSearch > 1 ? 's' : ''} minimum`
    : undefined;
}

const InputWithoutForwardRef = <T,>(
  {
    canBeReset,
    placeholder,
    displayValue,
    selectedItems,
    inputClassName,
    id,
    defaultValue,
    onSearchValueChange,
    resetValue,
    focused,
    onFocus,
    onBlur,
    searchValue,
    disabled,
    showSelection,
    minLengthToSearch,
  }: InputProps<T>,
  ref: Ref<HTMLInputElement>
) => (
  <label
    className={classnames(styles.searchLabel, inputClassName, {
      [styles.relative]: showSelection,
      [styles.searchLabelFocused]: focused,
    })}
    htmlFor={id}
  >
    <div className={classnames(styles.input, { [styles.inputOnFocus]: focused || !showSelection })}>
      <input
        disabled={disabled}
        autoComplete="off"
        onFocus={onFocus}
        onBlur={onBlur}
        type="search"
        id={id}
        placeholder={placeholder}
        value={searchValue}
        onChange={event => {
          onSearchValueChange(event.target.value);
        }}
        ref={ref}
      />
      <button className={styles.icon}>
        {searchValue.length >= minLengthToSearch ? <ClosePop /> : <Loupe />}
      </button>
    </div>
    {showSelection && (
      <div className={classnames(styles.value, { [styles.valueOnFocus]: focused })}>
        <p
          className={classnames({
            [styles.disabled]: disabled,
          })}
        >
          {displayValue ?? defaultValue}
        </p>
        {canBeReset && selectedItems.length > 0 ? (
          <button
            className={styles.buttonForSearchInput}
            onClick={event => {
              event.preventDefault();
              resetValue();
            }}
          >
            <ClosePop />
          </button>
        ) : (
          <RightArrow />
        )}
      </div>
    )}
  </label>
);

const Input = React.forwardRef(InputWithoutForwardRef) as <T>(
  p: InputProps<T> & { ref?: Ref<HTMLInputElement> }
) => ReactElement;

function Content<T>({
  searchResults,
  keyValueExtractor,
  onSearchResultClick,
  fetchStatus,
  selectedItems,
  searchValue,
  resetValue,
  canBeReset,
  defaultValue,
}: ResultsProps<T>) {
  const selectedKeys = selectedItems.map(item => keyValueExtractor(item).key);
  if (fetchStatus === FETCH_STATUS.PENDING) {
    return (
      <li key="loading-results" className={styles.searchStatusInfo}>
        <div className={styles.image}>
          <Loader heightInRem={2} />
        </div>
        <p className={styles.searchResultTitle}>en cours de chargement...</p>
      </li>
    );
  }

  if (fetchStatus === FETCH_STATUS.REJECTED) {
    return (
      <li key="error-results" className={styles.searchStatusInfo}>
        <div className={classnames(styles.image, styles.svg)}>
          <IllusEscalier />
        </div>
        <p className={styles.searchResultTitle}>Une erreur est survenue</p>
      </li>
    );
  }

  if (fetchStatus === FETCH_STATUS.FULFILLED && searchResults.length === 0) {
    return (
      <li key="empty-results" className={styles.searchStatusInfo}>
        <div className={classnames(styles.image, styles.svg)}>
          <Pasteque />
        </div>
        <p className={styles.searchResultTitle}>aucun résultat</p>
      </li>
    );
  }

  return (
    <FixedSizeList
      itemCount={canBeReset ? searchResults.length + 1 : searchResults.length}
      height={Math.min(224, 48 * searchResults.length)}
      width="100%"
      itemSize={48}
      className={styles.searchResultList}
    >
      {({ index, style }) => {
        if (canBeReset && index === 0) {
          return (
            <li key="reset-to-default">
              <button
                className={classnames(styles.searchResult, styles.buttonForSearchInput)}
                onClick={resetValue}
              >
                <div className={styles.matchingText}>{defaultValue}</div>
              </button>
              <div className={styles.separator} />
            </li>
          );
        } else {
          const searchResult = searchResults[canBeReset ? index - 1 : index];
          const { key, value, subValue } = keyValueExtractor(searchResult);
          const selected = selectedKeys.includes(key);
          return (
            <li key={key} style={style}>
              <button
                onClick={() => {
                  onSearchResultClick(searchResult);
                }}
                className={classnames(styles.searchResult, styles.buttonForSearchInput, {
                  [styles.selected]: selected,
                })}
              >
                <div className={styles.searchResultLabels}>
                  {highlightMatchingText(value, searchValue)}
                  {subValue && <div className={styles.subtitle}>{subValue}</div>}
                </div>
                {selected && <Check />}
              </button>

              {index !== (canBeReset ? searchResults.length : searchResults.length - 1) && (
                <div className={styles.separator} />
              )}
            </li>
          );
        }
      }}
    </FixedSizeList>
  );
}

const ResultsWithoutForwardRef = <T,>(
  props: ResultsProps<T> & Omit<React.ComponentProps<typeof Popup>, 'children'>,
  ref: Ref<PopupActions>
) => {
  const innerRef = React.useRef<PopupActions | null>(null);
  const combinedRef = useCombinedRefs(ref, innerRef);
  const [isOpened, setIsOpened] = useState(false);

  const handleScroll = useCallback(() => {
    innerRef?.current?.close();
  }, [innerRef]);

  useEffect(() => {
    if (isOpened) {
      document.getElementsByClassName('popup-overlay')[0]?.addEventListener('scroll', handleScroll);
    } else {
      document
        .getElementsByClassName('popup-overlay')[0]
        ?.removeEventListener('scroll', handleScroll);
    }
  }, [isOpened, handleScroll]);

  return (
    <Popup
      ref={combinedRef}
      {...props}
      arrow={false}
      contentStyle={{ width: `${props.width}px` }}
      onOpen={() => {
        setIsOpened(true);
        props.onOpen?.();
      }}
      onClose={() => {
        setIsOpened(false);
        props.onClose?.();
      }}
    >
      <ul>
        <Content {...props} />
      </ul>
    </Popup>
  );
};

const Results = React.forwardRef(ResultsWithoutForwardRef) as <T>(
  p: ResultsProps<T> &
    Omit<React.ComponentProps<typeof Popup>, 'children'> & { ref?: Ref<PopupActions> }
) => ReactElement;

function RawSearchInput<T>({
  id,
  defaultValue,
  canBeReset,
  selectedItems,
  searchResults,
  keyValueExtractor,
  search,
  onSearchResultClick,
  fetchStatus,
  minLengthToSearch,
  disabled,
  displayValue,
  showSelection,
  placeholder,
  inputClassName,
}: Props<T>) {
  const [focused, setFocus] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const resultsRef = useRef<PopupActions>(null);
  const resetValue = useCallback(() => {
    resultsRef.current?.close();
    onSearchResultClick(undefined);
  }, [onSearchResultClick, resultsRef]);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [resultWidth, setResultWidth] = useState(containerRef.current?.offsetWidth ?? 0);

  useEffect(() => {
    function handleResize() {
      setResultWidth(containerRef.current?.offsetWidth ?? 0);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef]);

  useEffect(() => {
    setResultWidth(containerRef.current?.offsetWidth ?? 0);
  }, [containerRef]);

  return (
    <div className={styles.container} ref={containerRef}>
      <Input
        disabled={disabled}
        inputClassName={inputClassName}
        id={id}
        canBeReset={canBeReset}
        placeholder={placeholder ?? defaultPlaceholder(minLengthToSearch)}
        defaultValue={defaultValue}
        selectedItems={selectedItems}
        searchValue={searchValue}
        showSelection={showSelection ?? true}
        onSearchValueChange={value => {
          setSearchValue(value);
          if (value.length >= minLengthToSearch) {
            search(value);
            setResultVisible(true);
            resultsRef.current?.open();
          }
        }}
        resetValue={resetValue}
        focused={focused}
        onFocus={() => {
          setFocus(true);
          if (searchValue.length >= minLengthToSearch) {
            setResultVisible(true);
            resultsRef.current?.open();
          }
        }}
        onBlur={() => {
          if (!resultVisible) {
            setSearchValue('');
            setFocus(false);
          }
        }}
        displayValue={displayValue}
        ref={inputRef}
        minLengthToSearch={minLengthToSearch}
      />

      <Results
        ref={resultsRef}
        searchValue={searchValue}
        canBeReset={canBeReset}
        defaultValue={defaultValue}
        resetValue={resetValue}
        searchResults={searchResults}
        keyValueExtractor={keyValueExtractor}
        onSearchResultClick={item => {
          onSearchResultClick(item);
          setSearchValue('');
          resultsRef.current?.close();
        }}
        onOpen={() => {
          if (minLengthToSearch <= 0) {
            search('');
          }
          inputRef.current?.focus();
        }}
        onClose={() => {
          setResultVisible(false);
          setSearchValue('');
          setFocus(false);
        }}
        fetchStatus={fetchStatus}
        selectedItems={selectedItems}
        trigger={<div className={styles.trigger}></div>}
        width={resultWidth}
      />
    </div>
  );
}

RawSearchInput.defaultProps = defaultProps;

export default RawSearchInput;

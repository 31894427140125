import Popup from 'reactjs-popup';

export enum RANDSTAD_APP {
  EDP = 'edp',
  FLASH = 'flash',
  RECRUTLIVE = "recrut'live",
  PUZZLE = 'puzzle',
  SATISFACTION = 'satisfaction',
  TDP = 'tdp',
  PIGE = 'pige',
  SCOPE = 'scope',
  IT = 'aide IT',
}

export enum BRAND {
  RANDSTAD = 'VB',
  INHOUSE = 'RIS',
  APPEL_MEDICAL = 'AM',
  EXPECTRA = 'EE',
  SEARCH = 'SEARCH',
}

export interface Props extends Omit<React.ComponentProps<typeof Popup>, 'children'> {
  isProd?: boolean;
  brandCode?: string;
}

import {
  CheckmarkCircle,
  CircleMoins,
  InfoFull,
  Sms,
  Warning,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';
import { toast } from 'react-toastify';

import styles from './ToastContainer.module.scss';

export const defaultToast = (label: string) => {
  return toast(
    <div className={styles.toastContent}>
      <Sms className={styles.icon} />
      <p className={styles.text}>{label}</p>
    </div>,
    {
      className: styles.defaultToastContainer,
      bodyClassName: styles.defaultBody,
    }
  );
};

export const errorToast = (label: string) => {
  return toast(
    <div className={styles.toastContent}>
      <CircleMoins className={styles.icon} />
      <p className={styles.text}>{label}</p>
    </div>,
    {
      className: classnames(styles.defaultToastContainer, styles.errorToastContainer),
      bodyClassName: styles.defaultBody,
    }
  );
};

export const successToast = (label: string) => {
  return toast(
    <div className={styles.toastContent}>
      <CheckmarkCircle className={styles.icon} />
      <p className={styles.text}>{label}</p>
    </div>,
    {
      className: classnames(styles.defaultToastContainer, styles.successToastContainer),
      bodyClassName: styles.defaultBody,
    }
  );
};

export const warningToast = (label: string) => {
  return toast(
    <div className={styles.toastContent}>
      <Warning className={styles.icon} />
      <p className={styles.text}>{label}</p>
    </div>,
    {
      className: classnames(styles.defaultToastContainer, styles.warningToastContainer),
      bodyClassName: styles.defaultBody,
    }
  );
};

export const infoToast = (label: string) => {
  return toast(
    <div className={styles.toastContent}>
      <InfoFull className={styles.icon} />
      <p className={styles.text}>{label}</p>
    </div>,
    {
      className: classnames(styles.defaultToastContainer, styles.infoToastContainer),
      bodyClassName: styles.defaultBody,
    }
  );
};

import React, { useContext, useRef, useState } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import Animation from '../../Animations';
import Popup from '../Popup/Popup.component';

import { PopupMenuContext } from './Context';
import { PopupMenuProps as Props } from './PopupMenu.types';

const PopupMenu = ({ children, trigger, selectedId, animated, ...props }: Props) => {
  const upperContext = useContext(PopupMenuContext);
  const popup = useRef<PopupActions>(null);
  const [opened, setOpened] = useState(false);
  // Using a custom React Component as trigger seems to break tooltip position calculation
  // We fix it by wrapping the trigger inside a span tag
  const enhancedTrigger = trigger && <span>{trigger}</span>;
  return (
    <Popup
      contentStyle={{ width: props.width ? `${props.width}px` : undefined }}
      trigger={enhancedTrigger}
      // Clicking outside also closes any parent menu (can be overriden via the onClose prop)
      {...props}
      ref={popup}
      onOpen={() => {
        setOpened(true);
        props.onOpen?.();
      }}
      onClose={() => {
        setOpened(false);
        props.onClose?.();
        upperContext?.()?.actions?.close();
      }}
      arrow={false}
    >
      <Animation.Unroll visible={opened || !animated}>
        <PopupMenuContext.Provider
          value={() => ({ actions: upperContext?.()?.actions ?? popup.current, selectedId })}
        >
          {children}
        </PopupMenuContext.Provider>
      </Animation.Unroll>
    </Popup>
  );
};

export default PopupMenu;

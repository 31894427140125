export const FETCH_STATUS = {
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FETCH_STATUS = typeof FETCH_STATUS[keyof typeof FETCH_STATUS];

export type Props<T> = {
  id: string;
  showSelection?: boolean;
  displayValue?: string;
  defaultValue: string;
  canBeReset: boolean;
  inputClassName?: string;
  selectedItems: T[];
  searchResults: T[];
  keyValueExtractor: (searchResult: T) => { key: string; value: string; subValue?: string };
  search: (value: string) => void;
  onSearchResultClick: (searchResult?: T) => void;
  minLengthToSearch: number;
  fetchStatus: FETCH_STATUS;
  disabled?: boolean;
  placeholder?: string;
};

export interface InputProps<T>
  extends Pick<
    Props<T>,
    | 'id'
    | 'selectedItems'
    | 'defaultValue'
    | 'canBeReset'
    | 'disabled'
    | 'displayValue'
    | 'showSelection'
    | 'inputClassName'
    | 'minLengthToSearch'
  > {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  placeholder?: string;
  focused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  resetValue: () => void;
}

export interface ResultsProps<T>
  extends Pick<
    Props<T>,
    | 'defaultValue'
    | 'searchResults'
    | 'keyValueExtractor'
    | 'onSearchResultClick'
    | 'fetchStatus'
    | 'canBeReset'
    | 'selectedItems'
  > {
  searchValue: string;
  resetValue: () => void;
  width: number;
}

export const defaultProps = {
  canBeReset: false,
  fetchStatus: FETCH_STATUS.FULFILLED,
  minLengthToSearch: -1,
};

import React from 'react';

import styles from './AppButton.module.scss';
import { Props } from './AppButton.types';

const AppButton = ({ name, link, icon }: Props) => {
  return (
    <div
      className={styles.container}
      onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
    >
      <div>{icon}</div>
      <div>{name}</div>
    </div>
  );
};

export default AppButton;

import classnames from 'classnames';
import React from 'react';

import { BaseProps } from '../Base/Base.types';
import { ContentProps } from '../Content/Content.types';

import styles from './buttons.module.scss';

export enum SIZE {
  XS,
  S,
  M,
  L,
}

export interface ButtonProps extends BaseProps, ContentProps {}

export const Sizeable = (Button: React.ComponentType<ButtonProps>) => {
  const defaultButton = ({ className, ...props }: ButtonProps) => {
    return <Button className={classnames(styles.S, styles.default, className)} {...props} />;
  };
  return Object.assign(defaultButton, {
    XSmall: ({ className, ...props }: ButtonProps) => (
      <Button className={classnames(styles.XS, styles.default, className)} {...props} />
    ),
    Small: ({ className, ...props }: ButtonProps) => (
      <Button className={classnames(styles.S, styles.default, className)} {...props} />
    ),
    Medium: ({ className, ...props }: ButtonProps) => (
      <Button className={classnames(styles.M, styles.default, className)} {...props} />
    ),
    Large: ({ className, ...props }: ButtonProps) => (
      <Button className={classnames(styles.L, styles.default, className)} {...props} />
    ),
  });
};

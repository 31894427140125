import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fr';

import { FlecheLeft, FlecheRight } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import fr from 'date-fns/locale/fr';
import moment from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import { Props } from './DatePicker.types';
import './DatePicker.scss';

const DatePicker = (props: Props) => {
  return (
    <ReactDatePicker
      inline
      weekLabel=" "
      fixedHeight
      locale={props.locale ?? fr}
      calendarClassName="react-datepicker__date-picker-container"
      showPopperArrow={false}
      shouldCloseOnSelect={false}
      openToDate={props.selected ?? new Date()}
      useWeekdaysShort
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="react-datepicker__custom-header">
          <p>{moment(date).format('MMMM YYYY')}</p>
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <FlecheLeft />
          </button>
          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <FlecheRight />
          </button>
        </div>
      )}
      {...props}
    />
  );
};

export default DatePicker;

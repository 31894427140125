import {
  Checkbox as FullCheckbox,
  EmptyCheckbox,
  DisabledCheckbox,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import styles from './Checkbox.module.scss';
import { Props } from './Checkbox.types';

const Checkbox = ({ label, children, checked, disabled, ...props }: Props) => (
  <div className={styles.container}>
    <label className={styles.label}>
      <input
        type="checkbox"
        style={{ display: 'none' }}
        disabled={disabled}
        checked={checked}
        {...props}
      />

      {!disabled && (checked ? <FullCheckbox /> : <EmptyCheckbox className={styles.hoveredIcon} />)}
      {disabled && <DisabledCheckbox />}
      <div className={classnames(styles.label, { [styles.disabled]: disabled })}>
        {label ?? children}
      </div>
    </label>
  </div>
);

export default Checkbox;

import React from 'react';

import styles from './WithLightTitle.module.scss';
import { Props } from './WithLightTitle.types';

const WithLightTitle = ({ title, textAlign, children, className, rightTitleComponent }: Props) => (
  <div className={className}>
    {rightTitleComponent ? (
      <div className={styles.titleWithRightComponent}>
        <p className={styles.title} style={{ textAlign: textAlign ?? 'left' }}>
          {title}
        </p>
        {rightTitleComponent}
      </div>
    ) : (
      <p className={styles.title} style={{ textAlign: textAlign ?? 'left' }}>
        {title}
      </p>
    )}
    {children}
  </div>
);

export default WithLightTitle;

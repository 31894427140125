import { ClosePop } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useImperativeHandle } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import Button from '../../Buttons';
import BaseModal from '../BaseModal';

import styles from './Modal.module.scss';
import { Props } from './Modal.types';

const Modal = React.forwardRef<PopupActions, Props>(
  (
    {
      title,
      children,
      footerActionsRight,
      footerActionsLeft,
      icon,
      trigger,
      hideCloseAction,
      subtitle,
      ...rest
    }: Props,
    ref
  ) => {
    const innerRef = React.useRef<PopupActions>(null);

    useImperativeHandle<PopupActions | null, PopupActions | null>(ref, () => ({
      close: () => innerRef.current?.close(),
      open: () => innerRef.current?.open(),
      toggle: () => innerRef.current?.toggle(),
    }));

    return (
      <BaseModal trigger={trigger} ref={innerRef} {...rest}>
        <div className={styles.container}>
          <div className={styles.icon}>{icon}</div>
          {!hideCloseAction && (
            <div className={styles.cross}>
              {
                <Button.Tertiary
                  onClick={() => {
                    innerRef.current?.close();
                  }}
                  style={{
                    padding: '0.5rem',
                    borderRadius: '0.5rem',
                    height: '2.5rem',
                    width: '2.5rem',
                    minWidth: '0px',
                  }}
                >
                  <ClosePop />
                </Button.Tertiary>
              }
            </div>
          )}
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.subtitle}>{subtitle ?? <></>}</div>
          <div className={styles.content}>{children}</div>
          <div className={styles.footer}>
            <div className={styles.sideFooter}>
              {footerActionsLeft?.map((footerAction, idx) => (
                <div key={`actionFooter${idx}`} className={styles.footerButton}>
                  {footerAction}
                </div>
              ))}
            </div>
            <div className={styles.sideFooter}>
              {footerActionsRight?.map((footerAction, idx) => (
                <div key={`actionFooter${idx}`} className={styles.footerButton}>
                  {footerAction}
                </div>
              ))}
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }
);

export default Modal;

import { BRAND, RANDSTAD_APP } from './RandstadAppsPopup.types';

const brandEcosystems: Record<BRAND, RANDSTAD_APP[]> = {
  [BRAND.RANDSTAD]: [
    RANDSTAD_APP.FLASH,
    RANDSTAD_APP.EDP,
    RANDSTAD_APP.RECRUTLIVE,
    RANDSTAD_APP.PUZZLE,
    RANDSTAD_APP.SATISFACTION,
  ],
  [BRAND.INHOUSE]: [RANDSTAD_APP.SATISFACTION, RANDSTAD_APP.FLASH, RANDSTAD_APP.TDP],
  [BRAND.APPEL_MEDICAL]: [RANDSTAD_APP.SATISFACTION],
  [BRAND.EXPECTRA]: [RANDSTAD_APP.SATISFACTION],
  [BRAND.SEARCH]: [RANDSTAD_APP.EDP],
};

export const getAppEcosystem = (brand: string): RANDSTAD_APP[] => {
  if (['VB', 'RDI'].includes(brand)) {
    return brandEcosystems[BRAND.RANDSTAD];
  }
  if (['RIS', 'ROS'].includes(brand)) {
    return brandEcosystems[BRAND.INHOUSE];
  }
  if (['AM', 'JBM'].includes(brand)) {
    return brandEcosystems[BRAND.APPEL_MEDICAL];
  }
  if (['EE', 'EXE'].includes(brand)) {
    return brandEcosystems[BRAND.EXPECTRA];
  }
  if (['RSS', 'AMS', 'EES'].includes(brand)) {
    return brandEcosystems[BRAND.SEARCH];
  }
  return brandEcosystems[BRAND.RANDSTAD];
};

import {
  EDPProd,
  FlashProd,
  PuzzleProd,
  RecrutLiveProd,
  SatisfactionProd,
  TDPProd,
  EDPUat,
  FlashUat,
  PuzzleUat,
  RecrutLiveUat,
  SatisfactionUat,
  TDPUat,
  ITProd,
  ITUat,
  PigeProd,
  PigeUat,
  ScopeProd,
  ScopeUat,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import { PopupActions } from '../../Modals';
import Popup from '../../Popups/Popup';

import AppButton from './AppButton';
import { getAppEcosystem } from './getAppEcosystem';
import styles from './RandstadAppsPopup.module.scss';
import { Props, RANDSTAD_APP } from './RandstadAppsPopup.types';

interface AppData {
  icon: { prod: React.ReactNode; uat: React.ReactNode };
  name: RANDSTAD_APP;
  link: { prod: string; uat: string };
}

const apps: AppData[] = [
  {
    icon: { prod: <EDPProd />, uat: <EDPUat /> },
    name: RANDSTAD_APP.EDP,
    link: {
      prod: 'https://etudedeposte.grouperandstad.fr',
      uat: 'https://recette.etudedeposte.grouperandstad.fr',
    },
  },
  {
    icon: { prod: <FlashProd />, uat: <FlashUat /> },
    name: RANDSTAD_APP.FLASH,
    link: {
      prod: 'https://flash.grouperandstad.fr',
      uat: 'https://recette.flash.grouperandstad.fr',
    },
  },
  {
    icon: { prod: <RecrutLiveProd />, uat: <RecrutLiveUat /> },
    name: RANDSTAD_APP.RECRUTLIVE,
    link: {
      prod: 'https://recrutlive.grouperandstad.fr',
      uat: 'https://recette.recrutlive.grouperandstad.fr',
    },
  },
  {
    icon: { prod: <PuzzleProd />, uat: <PuzzleUat /> },
    name: RANDSTAD_APP.PUZZLE,
    link: {
      prod: 'https://puzzleweb.grouperandstad.fr',
      uat: 'https://recette.puzzleweb.grouperandstad.fr',
    },
  },
  {
    icon: { prod: <SatisfactionProd />, uat: <SatisfactionUat /> },
    name: RANDSTAD_APP.SATISFACTION,
    link: {
      prod: 'https://satisfaction.grouperandstad.fr',
      uat: 'https://recette.satisfaction.grouperandstad.fr',
    },
  },
  {
    icon: { prod: <TDPProd />, uat: <TDPUat /> },
    name: RANDSTAD_APP.TDP,
    link: {
      prod: 'https://tourdeprod.grouperandstad.fr',
      uat: 'https://recette.tourdeprod.grouperandstad.fr',
    },
  },
  {
    icon: { prod: <PigeProd />, uat: <PigeUat /> },
    name: RANDSTAD_APP.PIGE,
    link: {
      prod: 'https://pige.grouperandstad.fr',
      uat: 'https://recette.pige.grouperandstad.fr',
    },
  },
  {
    icon: { prod: <ScopeProd />, uat: <ScopeUat /> },
    name: RANDSTAD_APP.SCOPE,
    link: {
      prod: 'https://randstad.service-now.com/',
      uat: 'https://randstad.service-now.com/',
    },
  },
  {
    icon: { prod: <ITProd />, uat: <ITUat /> },
    name: RANDSTAD_APP.IT,
    link: {
      prod: 'https://randstad.service-now.com/',
      uat: 'https://randstad.service-now.com/',
    },
  },
];

const RandstadAppsPopup = React.forwardRef<PopupActions, Props>(
  ({ isProd, brandCode, ...popupProps }, ref) => {
    return (
      <Popup ref={ref} contentStyle={{ padding: '0.5rem' }} {...popupProps}>
        {() => {
          let activeApps: AppData[] = apps;
          if (brandCode) {
            activeApps = apps.filter(app => getAppEcosystem(brandCode).includes(app.name));
          }
          return (
            <div className={styles.container}>
              {activeApps.map(({ icon, name, link }) => (
                <AppButton
                  icon={isProd ? icon.prod : icon.uat}
                  name={name}
                  link={isProd ? link.prod : link.uat}
                />
              ))}
            </div>
          );
        }}
      </Popup>
    );
  }
);

export default RandstadAppsPopup;
